require('./bootstrap');
require('datatables.net');
require('datatables.net-dt');
require('./content-builder');
require('./units');
require('./customer_responsibles');
require('./communities');
require('./connection_types');
require('./users');
require('./customers');
require('./module_variants');
require('./connection_types');
require('./notifications');
require('./connection_types');
require('./block-show');
require('./abhacken');
require('./content_builder_live_save')
require('./sidebar');
require('./question_box');
require('./comment_box');
require('./chat_filter');
require('./training-certs');

require('./module_management/management');
require('./admin-question-page');
require('./epd_user');
