$( document ).ready(function() {
    $('.content-accordion .card-body:not(.orphaned):not(.show_default)').hide();
});

$(document).on('click', '.content-accordion .card-header button:not(.with-propagation)', function(event) {
    event.stopPropagation();
});

$(document).on('click', '.content-accordion .card-header', function() {
    $(this).siblings('.card-body').toggle();

    if($(this).siblings('.card-body').css('display') === 'none'){
        $(this).siblings('.card-body').siblings().find('.fa-chevron-down').removeClass('open');
    }else{
        $(this).siblings('.card-body').siblings().find('.fa-chevron-down').addClass('open');
    }
    $(this).siblings('.card-body').children('.card').children('.card-body').show();
    $(this).siblings('.card-body').children('.card').find('.fa-chevron-down').addClass('open');
});

$(document).on('click', '.new-block-button', function() {
    $(this).closest('.card-header').siblings('.card-body').show();
    $('.new-block-form').hide();
    $('.new-block-form[data-type='+$(this).attr('data-type')+']').show();
});

$(document).on('keyup change', '.rows-input', function() {
    const split = $(this).attr('id').split("-");
    const rowCount = $("#table-" + split[1] + "-" + split[2] + " tr").length;
    const columnCount = $("#table-" + split[1] + "-" + split[2] + " tr:first td").length;

    const newRowCount = $(this).val();

    if (newRowCount > 0) {
        if (newRowCount < rowCount) {
            for (let i = rowCount; i > newRowCount; i--) {
                $("#table-" + split[1] + "-" + split[2] + " tr:last-child").remove();
            }
        } else if (newRowCount > rowCount) {
            for (let i = rowCount + 1; i <= newRowCount; i++) {
                let newRow = "<tr>";
                for (let j = 1; j <= columnCount; j++) {
                    newRow +=
                        "<td>"
                        + "<input type='text' class='form-control' name='cell_" + i + "_" + j + "_de' placeholder='german'>"
                        + "<input type='text' class='form-control' name='cell_" + i + "_" + j + "_fr' placeholder='french'>"
                        + "</td>";
                }
                newRow += "</tr>";
                $("#table-" + split[1] + "-" + split[2] + " tr:last-child").after(newRow);
            }
        }
    }
});

$(document).on('keyup change', '.columns-input', function() {
    const split = $(this).attr('id').split("-");
    const rowCount = $("#table-" + split[1] + "-" + split[2] + " tr").length;
    const columnCount = $("#table-" + split[1] + "-" + split[2] + " tr:first td").length;

    const newColumnCount = $(this).val();

    if (newColumnCount > 0) {
        if (newColumnCount < columnCount) {
            for (let i = columnCount; i > newColumnCount; i--) {
                $("#table-" + split[1] + "-" + split[2] + " tr td:last-child").remove();
            }
        } else if (newColumnCount > columnCount) {
            for (let i = 1; i <= rowCount; i++) {
                for (let j = columnCount + 1; j <= newColumnCount; j++) {
                    $("#table-" + split[1] + "-" + split[2] + " tr:nth-child(" + i + ") td:last-child").after(
                        "<td>"
                        + "<input type='text' class='form-control' name='cell_" + i + "_" + j + "_de' placeholder='german'>"
                        + "<input type='text' class='form-control' name='cell_" + i + "_" + j + "_fr' placeholder='french'>"
                        + "</td>"
                    );
                }
            }
        }
    }
});

function checkOrderValidity(card){
    const parent = card.parent();
    const cards = parent.children('.ordered-card');
    const count = cards.length;
    let current = 0;

    while(current < count){
        const currentCard = parent.children('.ordered-card[style*="order: '+current+'"]').first();
        if (currentCard.is('.ordered-card')){
            current++;
        } else {
            const container = parent.closest('.card');
            const id = container.attr("id").split("-")[2];

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            jQuery.ajax({
                url: '/content_container/fix_order/'+id,
                method: 'post',
                data: {
                    id: id,
                },
                success: function (data){
                    container.replaceWith(data.html);
                    //alert("Fixing corrupted data, please wait.");
                    //location.reload();
                }
            });

            break;
        }
    }
}

$(document).on('click', '.move-up', function() {
    const card =  $(this).closest('.ordered-card');
    let currentPosition = card.css("order");
    let previousPosition = parseInt(currentPosition)-1;
    let previousCard = card.siblings('.ordered-card[style*="order: '+previousPosition+'"]');

    if (previousCard.length === 1){
        previousCard = previousCard.first();
        card.css('order', previousPosition);
        previousCard.css('order', currentPosition);

        const csrf = $(this).attr('csrf');
        const routeCurrent = $(this).attr('route');
        const routePrevious = previousCard.find('.move-up').first().attr('route');
        change_order(previousPosition, routeCurrent, csrf);
        change_order(currentPosition, routePrevious, csrf);

        update_form(card,previousPosition);
        update_form(previousCard,currentPosition);
    } else {
        checkOrderValidity(card);
    }
});

$(document).on('click', '.move-down', function() {
    const card =  $(this).closest('.ordered-card');
    let currentPosition = card.css("order");
    let nextPosition = parseInt(currentPosition)+1;
    let nextCard = card.siblings('.ordered-card[style*="order: '+nextPosition+'"]');

    if (nextCard.length === 1){
        nextCard = nextCard.first();
        card.css('order', nextPosition);
        nextCard.css('order', currentPosition);

        const csrf = $(this).attr('csrf');
        const routeCurrent = $(this).attr('route');
        const routeNext = nextCard.find('.move-down').first().attr('route');
        change_order(nextPosition, routeCurrent, csrf);
        change_order(currentPosition, routeNext, csrf);

        update_form(card,nextPosition);
        update_form(nextCard,currentPosition);
    } else {
        checkOrderValidity(card);
    }
});

$(document).on("submit", ".ajax-form", function(event){
    event.preventDefault();

    const form = $(this);
    const csrf = form.attr('csrf');
    const card =  form.closest('.ordered-card');
    let currentPosition = parseInt(card.css("order"));

    const siblings = card.siblings('.ordered-card');
    siblings.each( function (){
        let siblingPosition = parseInt($(this).css("order"));
        if(siblingPosition>currentPosition){
            siblingPosition = siblingPosition-1;
            $(this).css('order', siblingPosition);

            const route = $(this).find('.move-up').first().attr('route');
            change_order(siblingPosition, route, csrf);
            update_form($(this),siblingPosition);
        }
    })

    event.currentTarget.submit();
});

export function update_form(card, order){
    const form = card.find('> .card-body > form').first();
    if (form.is('form')){
        form.find('input[name="order"]').val(order);
    }
}

export function change_order(order, route, csrf) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': csrf
        }
    });
    jQuery.ajax({
        url: route,
        method: 'post',
        data: {
            order: order,
        }
    });
}

function CleanPastedHTML(input) {
    let i;

    // 1. remove line breaks / Mso classes
    const stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
    let output = input.replace(stringStripper, ' ');

    // 2. strip Word generated HTML comments
    const commentSripper = new RegExp('<!--(.*?)-->', 'g');
    output = output.replace(commentSripper, '');

    // 3. remove tags leave content if any
    let tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi');
    output = output.replace(tagStripper, '');

    // 4. Remove everything in between and including tags '<style(.)style(.)>'
    const badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript'];
    for (i = 0; i< badTags.length; i++) {
        tagStripper = new RegExp('<'+badTags[i]+'.*?'+badTags[i]+'(.*?)>', 'gi');
        output = output.replace(tagStripper, '');
    }

    // 5. remove attributes ' style="..."'
    const badAttributes = ['style', 'start'];
    for (i = 0; i< badAttributes.length; i++) {
        var attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"','gi');
        output = output.replace(attributeStripper, '');
    }

    return output;
}

$( document ).ready(function() {
    $('.quill-editor').summernote({
        disableDragAndDrop: true,
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'italic', 'clear']],
            ['fontname', ['fontname']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link']],
            ['view', ['fullscreen', 'codeview']],
        ],
        callbacks: {
            onPaste: function (e) {
                e.preventDefault();

                var original = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html');
                var cleaned = CleanPastedHTML(original);

                document.execCommand('insertHtml', false, cleaned);
            }
        }
    });
});
