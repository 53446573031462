$(document).on('click', '#epd-user-gln-number-check', function () {
    if($(this).is(':checked')){
        Livewire.emit('clearRequestGfpNrForm');
    }else{
        Livewire.emit('clearGlnNumber');
    }
});

$(document).on('change','#epd-user-role',function () {
    if($(this).val() === 'gfp'){
        Livewire.emit('clearAssignmentToGfp');
    } else if($(this).val() === 'hip'){
        Livewire.emit('clearAssignmentToGroup');
    }
});

$(document).on('change','#epd-user-gln-number-request-check', function () {
    if($(this).val() == 0){
        Livewire.emit('clearRequestGfpNrForm');
    }
});

$(document).on('change','#epd-user-customer', function () {
    const customer_id = $(this).val();
    Livewire.emit('refreshEpdGroups', customer_id);
})
