$(document).on('change', '#training_certs_roles', function () {
    const roleId = $(this).val();
    const customerId = $('#epd-certs-customer').val();
    getPersons(roleId, customerId);
});

$(document).on('change', '#epd-certs-customer', function () {
    let personSelect = $('#person');
    let roleSelect = $('#training_certs_roles');
    personSelect.val([]);
    roleSelect.val([]);

})

window.onload = (event) => {
    const pathname = location.pathname;
    if( pathname === '/epd_training_certs/create'){
        const roleId = $('#training_certs_roles').val();
        const customerId = $('#epd-certs-customer').val();
        getPersons(roleId, customerId);

    }else if(pathname.match('\/epd_training_certs\/[1-9][0-9]*\/edit')){
        const customerId = $('#epd-certs-customer').val();
        const roleId = $('#training_certs_roles').val();
        const certId = pathname.substring( '/epd_training_certs/'.lastIndexOf("/") + 1, pathname.lastIndexOf("/"));
        getPersons(roleId, customerId, certId);
    }
};

function getPersons(roleId, customerId, certId=null){
    if(roleId !== ''){
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        jQuery.ajax({
            url: '/epd_training_certs/get_persons',
            method: 'get',
            data: {
                roleId: roleId,
                customerId: customerId,
                certId: certId
            },
            dataType: 'json',
            success: function(result){
                let personSelect = $('#person');
                personSelect.val([]);
                personSelect.find('option').remove().end();
                personSelect.append('<option value="">Person</option>');

                for (const key in result[0]) {
                    personSelect.append($("<option></option>").attr("value", key).text(result[0][key]));
                }

                if(result[1] !== 0){
                    personSelect.val(result[1]);
                }

            },
            error: function (request, status, error) {
                console.log(request.responseText);
            }
        });
    }
}
