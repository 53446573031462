$('.open-units').on('click', function () {
    console.log('open')
    let _this = $(this);
    let table_id = '#unit-list-' + _this.data("id");
    console.log(_this.first());

    if ($(table_id).is(':visible')) {

        $(table_id).slideUp(300);
        _this.children().css('transform', 'rotate(0deg)');
    } else {
        _this.children().css('transform', 'rotate(90deg)');
        $(table_id).slideDown(300);
    }
});


$(document).ready(function () {
    var wrapper_de = $('.field_wrapper_de'); //Input field wrapper
    var fieldHTML_de = "<input class=\"form-control\" name=\"list_item_de[]\" type=\"text\" style=\"margin-top: 5px\">";

    var wrapper_fr = $('.field_wrapper_fr'); //Input field wrapper
    var fieldHTML_fr = "<input class=\"form-control\" name=\"list_item_fr[]\" type=\"text\" style=\"margin-top: 5px\">";

    //Once add button is clicked
    $(document).on('click', '#add-list-text-row', function () {
        //Check maximum number of input fields
        $(wrapper_de).append(fieldHTML_de);
        $(wrapper_fr).append(fieldHTML_fr);//Add field html
    });

    $(document).on('click', '.remove-list-text-row', function () {
        const form = $(this).closest('form');
        form.find('.field_wrapper_de').children('.form-control').last().remove();
        form.find('.field_wrapper_fr').children('.form-control').last().remove();
    });

    var headingAddButton = $('#add-heading-text-row'); //Add button selector
    var headingWrapper_de = $('.heading_wrapper_de'); //Input field wrapper
    var headingFieldHTML_de = "<input class=\"form-control\" name=\"heading_item_de[]\" type=\"text\">";

    var headingWrapper_fr = $('.heading_wrapper_fr'); //Input field wrapper
    var headingFieldHTML_fr = "<input class=\"form-control\" name=\"heading_item_fr[]\" type=\"text\">";

    //Once add button is clicked
    $(headingAddButton).click(function () {
        //Check maximum number of input fields
        $(headingWrapper_de).append(headingFieldHTML_de);
        $(headingWrapper_fr).append(headingFieldHTML_fr);//Add field html
    });

    var rowAddButton = $('#add-row-text-row'); //Add button selector
    var rowWrapper_de = $('.row_wrapper_de'); //Input field wrapper
    var rowFieldHTML_de = "<input class=\"form-control\" name=\"row_item_de[]\" type=\"text\">";

    var rowWrapper_fr = $('.row_wrapper_fr'); //Input field wrapper
    var rowFieldHTML_fr = "<input class=\"form-control\" name=\"row_item_fr[]\" type=\"text\">";

    //Once add button is clicked
    $(rowAddButton).click(function () {
        //Check maximum number of input fields
        $(rowWrapper_de).append(rowFieldHTML_de);
        $(rowWrapper_fr).append(rowFieldHTML_fr);//Add field html
    });

    $('.dropdown .dropdown-menu .dropdown-item').on('click', function(){
        let value = $(this).val($(this).html()).val();
        console.log(value);

        if(value === 'Titel'){
            console.log(value);
            $('#content-title').removeAttr('hidden');
        }

        if(value === 'Paragraph'){
            console.log(value);
            $('#content-paragraph').removeAttr('hidden');
        }

        if(value === 'Liste'){
            console.log(value);
            $('#content-list').removeAttr('hidden');
        }

        if(value === 'Tabelle'){
            console.log(value);
            $('#content-table').removeAttr('hidden');
        }

        if(value === 'Image'){
            console.log(value);
            $('#content-image').removeAttr('hidden');
        }

        if(value === 'Inhaltsblock'){
            console.log(value);
            $('#content-container').removeAttr('hidden');
        }
    });
    $('.show-content-btn').on('click', function(){
        let id = "#update_content_" + $('.show-content-btn').val();
        console.log(id);
        $(id).removeAttr('hidden');

        // let _this = $(this);
        // let table_id = '#open-units-' + _this.data("id");
        // console.log(_this.first());
        //
        // if ($(table_id).is(':visible')) {
        //
        //     $(table_id).slideUp(300);
        //     _this.children().css('transform', 'rotate(0deg)');
        // } else {
        //     _this.children().css('transform', 'rotate(90deg)');
        //     $(table_id).slideDown(300);
        // }
    })
});
