$(document).ready(function () {
    $('#customers-table').DataTable({
        dom: 'fBlrtip',
        language: {
            url: '/data_tables_translation.json'
        }
    });

    // $('.status-select').each(
    //     function(index){
    //         var string = "#variant-select-" + $(this).prop('id').split('-')[2];
    //         if($(this).val() == 2){
    //             $(string).prop("disabled", true);
    //         }else{
    //             $(string).prop("disabled", false);
    //         }
    //     }
    // );
});

$(document).on('click', '.update-customer-url', function (){
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    const button = $(this);
    const prompt = button.text();

    const form_id = button.attr('data-formId');
    const form = $("#"+form_id);

    jQuery.ajax({
        url: form.attr("action"),
        type: "put",
        data: {
            url_key: form.parent().find('input[name="url_key"]').val(),
            url: form.parent().find('input[name="url"]').val(),
        },
        beforeSend: function () {
            button.html('<i class="fas fa-spinner fa-spin"></i>')
            button.prop('disabled', true);
        },
        success: function (data) {
            toastr.success('Aktualisiert');
            button.html(prompt);
            button.prop('disabled', false);
        },
        error: function () {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
            button.html(prompt);
            button.prop('disabled', false);
        }
    });
});

$(document).on('click', '.remove-customer-url', function (){
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    const button = $(this);
    const prompt = button.text();

    const form = button.closest('form');

    jQuery.ajax({
        url: form.attr("action"),
        type: "post",
        data: form.serialize(),
        beforeSend: function () {
            button.html('<i class="fas fa-spinner fa-spin"></i>')
            button.prop('disabled', true);
        },
        success: function () {
            toastr.success('Inhalt entfernt');
            button.closest('tr').remove();

            form[0].reset();
        },
        error: function () {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
            button.html(prompt);
            button.prop('disabled', false);
        }
    });
});

$(document).on('click', '.add-customer-url', function(){
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    const button = $(this);
    const prompt = button.text();

    const form_id = button.attr('data-formId');
    const form = $("#"+form_id);

    jQuery.ajax({
        url: form.attr("action"),
        type: "post",
        data: form.serialize(),
        beforeSend: function () {
            button.html('<i class="fas fa-spinner fa-spin"></i>')
            button.prop('disabled', true);
        },
        success: function (data) {
            toastr.success('Gespeichert');
            $("#customer-urls-table").find('tr:last').before(data.html);
            button.html(prompt);
            button.prop('disabled', false);

            form[0].reset();
        },
        error: function () {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
            button.html(prompt);
            button.prop('disabled', false);
        }
    });
});

// $(document).on('change', 'select', function() {
//     if (this.id.split('-')[0] === 'status') {
//         var string = "#variant-select-" + this.id.split('-')[2];
//         if (this.value == 2) {
//             $(string).prop("disabled", true);
//         } else {
//             $(string).prop("disabled", false);
//         }
//     }
// });


$(document).on('click', '#add-third-party', function (){
    let id = parseInt($(this).attr("data-fieldCount"))+1;
    $(this).attr("data-fieldCount", id);

    let html = "<li class=\"list-group-item p-2\"><input name='third_party_order_data_"+id+"' type='file'></li>";

    $("#third-party-list").append(html);
});

$(document).on('click', '#add-professional-secrecy', function (){
    let id = parseInt($(this).attr("data-fieldCount"))+1;
    $(this).attr("data-fieldCount", id);

    let html = "<li class=\"list-group-item p-2\"><input name='professional_secrecy_"+id+"' type='file'></li>";

    $("#professional-secrecy-list").append(html);
});

$(document).on('click', '#add-patient-data-protection', function (){
    let id = parseInt($(this).attr("data-fieldCount"))+1;
    $(this).attr("data-fieldCount", id);

    let html = "<li class=\"list-group-item p-2\"><input name='patient_data_protection_"+id+"' type='file'></li>";

    $("#patient-data-protection-list").append(html);
});

$(document).on('click', '#add-request-information', function (){
    let id = parseInt($(this).attr("data-fieldCount"))+1;
    $(this).attr("data-fieldCount", id);

    let html = "<li class=\"list-group-item p-2\"><input name='request_information_"+id+"' type='file'></li>";

    $("#request-information-list").append(html);
});

$(document).on('click', '#add-data-protection', function (){
    let id = parseInt($(this).attr("data-fieldCount"))+1;
    $(this).attr("data-fieldCount", id);

    let html = "<li class=\"list-group-item p-2\"><input name='data_protection_"+id+"' type='file'></li>";

    $("#data-protection-list").append(html);
});

$(document).on('click', '#add-certificates-management', function (){
    let id = parseInt($(this).attr("data-fieldCount"))+1;
    $(this).attr("data-fieldCount", id);

    let html = "<li class=\"list-group-item p-2\"><input name='certificates_management_"+id+"' type='file'></li>";

    $("#certificates-management-list").append(html);
});

$(document).on('click', '#add-declaration-of-consent', function (){
    let id = parseInt($(this).attr("data-fieldCount"))+1;
    $(this).attr("data-fieldCount", id);

    let html = "<li class=\"list-group-item p-2\"><input name='declaration_of_consent_"+id+"' type='file'></li>";

    $("#declaration-of-consent-list").append(html);
});


