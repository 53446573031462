$(document).ready(function () {
    $(function () {
        $('.datepicker').datepicker({
            isRTL: false,
            format: 'dd/mm/yyyy',
            language: 'de'
        })
    })

    $('.datepicker').on('changeDate', function(){
        $(this).datepicker('hide');
    });

    $(function () {
        $('.timepicker').timepicker({
            language: 'de-DE',
            timeFormat: 'HH:mm',
            interval: 60,
            startTime: '00:00',
            dynamic: true,
            dropdown: true,
            scrollbar: true
        })
    })

    $(function(){
        var url = window.location.href;

        var id_href = url.substring(url.lastIndexOf('#') + 1);

        if(id_href !== url) {
            unhide_containers(id_href);
        }
    })

    let $token = $('#token').val();

    $(document).on('click', '.add-reminder', function(){
        let unique_id = $(this).attr('data-uniqueid');
        let date = $('#datepicker-'+unique_id).val();
        let updateButton = $(this);

        const id_field = $('#user_block_id-'+unique_id);
        let reminder_id = unique_id;
        if(id_field.length > 0){
            reminder_id = id_field.val();
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $token
            }
        });
        $.ajax({
            url: location.protocol + '//' + location.host + '/reminders/update/'+reminder_id,
            method: 'put',
            data: {
                date: date,
                time: $('#timepicker-'+unique_id).val(),
                task_id: $('#task_id-'+unique_id).val(),
            },
            success: function(result){
                $('#date-restore-'+unique_id).val(date);

                updateButton.closest('.modal-content').find('#close').click();

                //document.getElementById("close").click();
                $('#reminder-modal-'+unique_id).modal('hide');
                $('#reminder-date-'+result.id).html(result.data);
            }});
    });

    $(document).on('click', '.add-reminder-null', function(){
        let unique_id = $(this).data('uniqueid');
        let date = $('#datepicker-'+unique_id).val();
        let type = $('#type-'+unique_id).val();
        let addButton = $(this);

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $token
            }
        });
        $.ajax({
            url: location.protocol + '//' + location.host + '/reminders/store',
            method: 'post',
            data: {
                date: date,
                type: type,
                time: $('#timepicker-'+unique_id).val(),
                task_id: $('#task_id-'+unique_id).val(),
            },
            success: function(data){
                $('#date-restore-'+unique_id).val(date);
                addButton.html(addButton.attr('data-backuplabel'));

                const id = $('#task_id-'+unique_id).val();
                const button = $('#'+id);
                const childListItems = button.closest(".list-block").siblings(".list-item-custom");

                button.closest('li').addClass('blue-button');
                const reminderIndicators = button.parents('.card-body').siblings('.content-container-header').find('.reminders-indicator');
                reminderIndicators.addClass('blue-button');

                const tocReminderIndicator = $('.toc-container-reminder[data-contentContainerId=\"' + id + '\"]')
                tocReminderIndicator.parent().addClass('blue-button');

                reminderIndicators.each(function() {
                    const container_id = $(this).attr('data-contentContainerId');
                    $('.table-of-contents').find('.reminders-indicator[data-contentContainerId='+container_id+']').addClass('blue-button');
                });

                button.closest('.can_color').addClass('row_with_reminder');
                childListItems.closest('.can_color').addClass('row_with_reminder');

                addButton.closest('.modal-content').find('#close').click();
                addButton.removeClass('add-reminder-null');
                addButton.addClass("add-reminder");
                addButton.attr("data-uniqueId", data.id);

                //document.getElementById("close").click();
                $('#reminder-modal-'+unique_id).modal('hide');
            }});
    });

    $(document).on('click', '.toc-container-reminder', function(){
        const container = $('#container-'+$(this).attr('data-contentContainerId'));
        $('html, body').animate({
            scrollTop: container.offset().top - 80
        }, 1000);
    });

    $(".modal").on("hidden.bs.modal", function () {
        const dateRestore = $(this).find('.date-restore');
        $(this).find('.datepicker').val(dateRestore.val());
    });

    $(document).on("click", ".open-modal", function () {
        var block_id = $(this).data('id');
        var block_label = $(this).data('blocklabel');
        var mode = $(this).data('mode');
        var url = $(this).data('url');
        var target = $(this).data('target');
        let uniqueid = $(this).data('uniqueid');

        if (mode === "text") {
            let location = document.URL.split('/')[3];
            if(location === 'reminders'){
                $('.module-text').html('<strong>Modul: </strong>'+$(this).closest('.card').parent().parent().siblings().find('.card-title a').text());
                $('.category-text').html('<strong>Kategorie: </strong>'+$(this).closest('.card').find('.card-title a').text());
            }else{
                $('.module-text').html('<strong>Modul: </strong>'+$('.module-title').text());
                $('.category-text').html('<strong>Kategorie: </strong>'+$(this).closest('.card-body').parent().parent().siblings('.card-header').find('.content-container-title').text());
            }

            $(target + ' .modal-body #task_name').html(block_label);
            $('#task_id-'+uniqueid).val(block_id);
        }
        else {
            $(target + ' .modal-body #task_name').html("Task");
            $(target + ' .modal-body #task_url').html(url);
        }
    });
});

$(document).on('click', '.toggle-questions', function(){
    const row = $(this).closest('.row');
    const questionsBox = row.children('.questions-wrap');
    questionsBox.toggle();
})

$(document).on('click', '.toggle-replies .replies', function(event){
    event.stopPropagation();
})

$(document).on('click', '.toggle-replies', function(){
    const question = $(this).closest('.question');
    const replies = question.children('.replies');
    replies.toggle();

    let rsp_history = replies.find(`.rsp_history`);

    rsp_history.each(function(){
        $(this).scrollTop($(this)[0].scrollHeight);
    });
})

function unhide_containers(id_href) {
    let parent = $('#' + id_href).closest('.card');
    while (parent.parent('.card-body').parent('.card').length > 0) {
        parent.children('.card-body').show();
        parent = parent.parent('.card-body').parent('.card');
    }
    parent.children('.card-body').show();
}

$(document).on('click', '.switch-order', function(){


    if($(this).html() === 'Datum <em class="fas fa-arrow-up"></em>'){
        var order = 'asc';
    }else{
        var order = 'desc';
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    jQuery.ajax({
        url: '/reminders-grouped/show',
        method: 'get',
        data: {
            order: order
        },
        dataType: 'json',
        success: function(result){

            let module_name = $('.reminder-module.card').children().find('.card-title a').html().trim();

            $('.reminder-unit.card').each(function (index) {
                let unit_name = $(this).children().find('.card-title a').html().trim();
                if(result[module_name][unit_name].length > 1){
                    let container = $(this).children().find('.card-body');
                    container.children().each(function(i,div){container.prepend(div)})
                }
            });

            if(order === 'asc'){
                $('.switch-order').html('Datum <em class="fas fa-arrow-down"></em>');
            }else {
                $('.switch-order').html('Datum <em class="fas fa-arrow-up"></em>');
            }

        },
        error: function (request, status, error) {
            console.log(request.responseText);
        }
    });
})

