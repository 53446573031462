$(document).ready(function () {
    let token = $('#token').val();

    $('#filter-option').change(function () {
        var valSelected = $('#filter-option').find(":selected").val();

        if(valSelected !== '0'){
            let route = '';

            if(valSelected === '1'){
                route = 'unit';
            }else if(valSelected === '2'){
                route = 'customer';
            }
            resetSelects();


            fillSelect(route);

            if($('#filter-by-'+route).css('display') === 'none'){
                $('#filter-by-'+route).css('display', 'block');
            }

        }else{
            resetSelects();

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': token
                }
            });
            $.ajax({
                url: location.protocol + '//' + location.host + '/filter/all' ,
                method: 'get',
                data: {
                    id: ''
                },

                success: function(result){
                    generateHtml(result.data, result.logged_user);
                }});
        }

    });

    $('#filter-by-unit').change(function () {

        const valSelected = $('#filter-by-unit').find(":selected").val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token
            }
        });
        $.ajax({
            url: location.protocol + '//' + location.host + '/filter/units' ,
            method: 'get',
            data: {
                id: valSelected
            },
            success: function(result){
                console.log(result);
                generateHtml(result.data, result.logged_user);
            }});
    });

    $('#filter-by-customer').change(function () {
        const valSelected = $('#filter-by-customer').find(":selected").val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token
            }
        });
        $.ajax({
            url: location.protocol + '//' + location.host + '/filter/customer' ,
            method: 'get',
            data: {
                id: valSelected
            },
            success: function(result){
                console.log(result);
                generateHtml(result.data, result.logged_user);
            }});
    });
});

function resetSelects(){
    $('#filter-by-unit').css('display', 'none');
    $('#filter-by-customer').css('display', 'none');
}

function generateHtml(array, user){
    let html = '';
    array.forEach((element) => {

        let cid = '0';
        let content = '';
        if(element.comment_box != null) {
            cid = element.comment_box.id;
        }
        content += '<div class="card-header open_chatbox toggle-comments" data-commentBoxId="'+cid+'"> ' +
            '<h6 class="align-self-center mb-0">'+ element.customer_company_name + ':' + element.content_container_title + '</h6> </div>' +
            '<div class="card-body p-1">' +
            '<div class="comments-area" id="comments-'+ cid +'"><div class="messaging"><div class="inbox_msg"><h3 class="pl-4 pt-3">Nachrichtenverlauf</h3>' +
            '<div class="mesgs"><div class="msg_history" id="message_history_'+ cid +'" data-commentBoxId="'+ cid +'">';

        if(element.comment_box_transformed.comments.length === 0){
            content += '<h5 class="p-3" style="font-family: Roboto, sans-serif;" id="notice_'+ cid +'">' +
                'Bisher sind keine Nachrichten geschrieben worden. Erfassen Sie die erste Nachricht</h5>';
        }

        let message_owner = '';

        element.comment_box_transformed.comments.forEach((comm_box)=>{

            let chats = '';

            let cls ='';

            chats += '<div class="custom-row">';

            if(comm_box.user.id === user.id){
                if(message_owner !== 'me'){
                    cls = 'switch';
                }

                chats += '<div class="message outgoing_msg '+ cls +'"> ' +
                    '<div class="message_body"> ' +
                    '<div class="message_text_wrap"> ' +
                    '<div class="message_text">' +
                    '<p class="user_name"><span style="font-weight: normal">' + comm_box.time_difference +'</span> '+ comm_box.user.name + ' ' + comm_box.user.surname + '</p> ' +
                    '<p>'+comm_box.text+'</p> </div> </div>' +
                    '<div class="message_image">'+comm_box.user.surname.substring(0,1) + comm_box.user.name.substring(0, 1)+' </div> </div> </div>';
                message_owner = 'me';
            } else{
                if(message_owner !== 'other'){
                    cls = 'switch';
                }
                chats += '<div class="message incoming_msg '+ cls +'"> ' +
                    '<div class="message_body"> ' +
                    '<div class="message_image">'+comm_box.user.surname.substring(0,1) + comm_box.user.name.substring(0, 1)+'</div> ' +
                    '<div class="message_text_wrap"> ' +
                    '<div class="message_text"><p class="user_name">'+comm_box.user.name+' '+ comm_box.user.surname +'<span style="font-weight: normal">'+ comm_box.time_difference +'</span></p>' +
                    '<p>'+ comm_box.text +'</p></div> </div> </div> </div>';
                message_owner = 'other';
            }
            chats += '</div>';

            content += chats;
        });

        content += '<input type="hidden" name="unit_id" value="'+ element.container.unit_id +'" id="unit_id">';

        content += '</div><div class="type_msg"> <div class="input_msg_write p-2">' +
            '<textarea class="write_msg ml-2 p-2" id="message_input_'+ cid +'"' +
            'data-commentBoxId="'+ cid +'" data-containerId="'+ element.container.id +'"' +
            ' data-username="'+ user.name+' '+user.surname +'"' +
            'placeholder="Bitte schreiben Sie hier Ihre Nachricht"></textarea><button type="button" class="msg_send_btn epd-primary mr-2 p-1"' +
            'data-commentBoxId="'+ cid +'" ' +
            'data-containerId="'+ element.container.id +'" ' +
            'data-username="'+user.name+' '+user.surname+'">Senden</button>' +
            '</div></div></div></div></div>';

        content += '</div></div>';

        html += content;
    })

    $('#chat-container').html(html);
    $('.comments-area').hide();
}

function fillSelect(route){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': token
        }
    });
    $.ajax({
        url: location.protocol + '//' + location.host + '/getOptions/'+route ,
        method: 'get',
        data: {
            id: ''
        },
        success: function(result){
            let html = '<option value="" disabled selected readonly></option>';

            for (const [key, value] of Object.entries(result.data)) {
                html += '<option value="'+ key +'">'+ value +'</option>';
            }
            $('#filter-by-'+route).html(html);
        }});
}
