$(document).ready(function () {
    var div = document.getElementsByClassName('notification')[0];
    if (typeof div != "undefined") {
        var id = div.id.split('-')[1];

        Echo.channel('notify' + id)
            .listen('NotificationEvent', e => {
                const url_destination = window.location.protocol + '//' + window.location.host + e.notification.url_destination;
                console.log("Got notification", e.notification);
                //console.log($('.notification-link::after'))
                $('.notification-link').addClass('sidebar-new-notification');

                console.log(e.notification.comment_box_id);
                if (e.notification.comment_box_id !== null && e.notification.comment_box_id !== "") {
                    $('.chatbox-' + e.notification.comment_box_id).parent().addClass('comment-new-messages');
                    console.log('.chatbox-' + e.notification.comment_box_id);
                }


                if (e.notification.hasOwnProperty('sender')) {

                    $(document).Toasts('create', {
                        title: e.notification.sender.name + ' ' + e.notification.sender.surname + " hat eine Nachricht geschrieben.",
                        autohide: true,
                        delay: 10000,
                        body: '<a href="' + url_destination + '">' + e.notification.message + '</a>'
                    })
                } else {
                    $(document).Toasts('create', {
                        title: 'Neue Frage',
                        autohide: true,
                        delay: 10000,
                        body: '<a href="' + url_destination + '">' + e.notification.message + '</a>'
                    })
                }
            });
    }

});

$(document).ready(function () {
    const result = $('.unread-notifications-container .date-sortable').sort(function (a, b) {
        const dateA = $(a).attr('data-sortDate');
        const dateB = $(b).attr('data-sortDate');
        return (dateA > dateB) ? -1 : (dateA < dateB) ? 1 : 0;
    });

    $('.unread-notifications-container').html(result);
});

$(document).ready(function () {
    $(document).on('click', '.toggle-notification-comments', function () {
        let comment_box_id = $(this).attr('data-commentBoxId');
        $('.comments-area#comments-' + comment_box_id).toggle();
        console.log(comment_box_id);

        var mydiv = $(`#message_history_${comment_box_id}`);
        mydiv.scrollTop(mydiv.prop("scrollHeight"));
    })
})

$(document).ready(() => {

    jQuery('#new-notifications-toggle-button').on('click', function () {
        jQuery('#new-notifications-toggle-button').addClass('active-tab-button')
        jQuery('#archived-notifications-toggle-button').removeClass('active-tab-button')
        jQuery('.unread-notifications-container').removeClass('d-none');
        jQuery('.read-notifications-container').addClass('d-none');
    });

    jQuery('#archived-notifications-toggle-button').on('click', function () {
        jQuery('#new-notifications-toggle-button').removeClass('active-tab-button')
        jQuery('#archived-notifications-toggle-button').addClass('active-tab-button')
        jQuery('.read-notifications-container').removeClass('d-none');
        jQuery('.unread-notifications-container').addClass('d-none');
    });

    jQuery('.read-notification-button').click(function (e) {
        readNotification($(this));
    });

    jQuery('.read-question-notification-button').click(function (e) {
        readQuestionNotification($(this));
    });

    jQuery('.read-all-notifications-button').click(function () {
        readNotificationFromContainer($(this));
        //var notification_ids = JSON.parse($(this).attr('data-notificationIds'));
        // notification_ids.forEach(id => {
        //     console.log(id);
        //     jQuery('#read-notification-button-' + id).trigger('click');
        // });
    })

    jQuery('.container-breadcrumb').click(function (e) {
        var url = jQuery($(this)).attr('data-href');
        window.open(url, '_top').focus();
    })

    $(document).on('click', '.question .replies', function (event) {
        event.stopPropagation();
    })

    $(document).on('click', '.toggle-question-replies-button', function () {
        const question = $(this).closest('.question');
        const replies = question.children('.replies');
        const button_id = $(this).attr('data-questionBoxId');
        const text_closed = $(this).attr('data-buttonTextClosed');
        const text_open = $(this).attr('data-buttonTextOpen');
        var button = jQuery('#toggle-replies-' + button_id);
        if (button.html() == text_closed) {
            button.html(text_open);
        } else {
            button.html(text_closed);
        }
        replies.toggle();
    })
});

function readQuestionNotification(clickedButton) {
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });
    var context = clickedButton;
    var notification_id = clickedButton.attr('data-notificationId');
    var container_id = clickedButton.attr('data-containerId');

    jQuery.ajax({
        url: '/read_selected',
        type: 'post',
        data: {
            'notification_id': notification_id
        },
        beforeSend: function () {
            context.html('<i class="fas fa-spinner fa-spin"></i>')
        },
        success: function (data) {
            var notification = jQuery('#unread-question-notification-group-' + container_id);
            context.remove();
            jQuery('#new-archived-group-container').prepend(notification);
        },
        error: function (data) {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
        }
    })
}

function readNotification(clickedButton) {
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });
    var context = clickedButton;
    var notification_id = clickedButton.attr('data-notificationId');
    var container_id = clickedButton.attr('data-containerId');

    jQuery.ajax({
        url: '/read_selected',
        type: "post",
        data: {
            "notification_id": notification_id
        },
        beforeSend: function () {
            context.html('<i class="fas fa-spinner fa-spin"></i>')
        },
        success: function (data) {
            var notification = jQuery('#notif_' + notification_id);
            var parent_container = notification.parent('.card-body');
            var module_container = notification.closest('.card-module');
            notification.removeClass('notification-card');
            context.remove();
            if (jQuery('#read-notification-group-' + container_id).length) {
                jQuery('#archived-notifications-container-' + container_id + ' .new-archived-container').prepend(notification);
            } else {
                jQuery('#unread-notification-group-' + container_id).clone().appendTo('#new-archived-group-container');

                var new_archived_group_container = jQuery('#new-archived-group-container #unread-notification-group-' + container_id + ' .card-body');
                var new_archived_div = jQuery("<div/>").addClass("new-archived-container");
                new_archived_group_container.empty();
                new_archived_group_container.prop('id', 'archived-notifications-container-' + container_id);
                new_archived_group_container.append(new_archived_div);

                jQuery('#new-archived-group-container #unread-notification-group-' + container_id).prop('id', 'read-notification-group-' + container_id);
                jQuery('#new-archived-group-container .read-all-notifications-button').remove();
                jQuery('#new-archived-group-container .read-notification-button').remove();
                jQuery('#new-archived-group-container #read-notification-group-' + container_id + ' .card-body').prepend(notification);
            }
            if (parent_container.children().length == 0) {
                module_container.remove();
            }
            updateEmptyMessages();
        },
        error: function (data) {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
        }
    });
}

function readNotificationFromContainer(clickedButton){
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    var context = clickedButton;

    const group = clickedButton.closest('.card').attr('id');

    jQuery.ajax({
        url: '/read_selected_group',
        type: "post",
        data: {
            "box_id": group
        },
        beforeSend: function () {
            context.html('<i class="fas fa-spinner fa-spin"></i>')
        },
        success: function (result) {
            var module_container = clickedButton.closest('.card-module');
            var notifications = module_container.children().find('[id^=notif_]');
            var container_id = notifications.first().children().find('.read-notification-button').attr('data-containerId');

            notifications.each(function( index ) {
                var notification = $(this);
                var parent_container = notification.parent('.card-body');
                var button = notification.children().find('.read-notification-button');
                button.remove();

                notification.removeClass('notification-card');
                context.remove();

                const container = jQuery('#read-notification-group-' + container_id);

                if (container.length) {
                    var len = container.children().find('[id^=notif_]').length;
                    if(len < 3){
                        jQuery('#archived-notifications-container-' + container_id + ' .new-archived-container').prepend(notification);
                    }else{
                        container.children().find('[id^=notif_]').last().remove();
                        jQuery('#archived-notifications-container-' + container_id + ' .new-archived-container').prepend(notification);

                    }


                } else {
                    jQuery('#unread-notification-group-' + container_id).clone().appendTo('#new-archived-group-container');

                    var new_archived_group_container = jQuery('#new-archived-group-container #unread-notification-group-' + container_id + ' .card-body');
                    var new_archived_div = jQuery("<div/>").addClass("new-archived-container");
                    new_archived_group_container.empty();
                    new_archived_group_container.prop('id', 'archived-notifications-container-' + container_id);
                    new_archived_group_container.append(new_archived_div);

                    jQuery('#new-archived-group-container #unread-notification-group-' + container_id).prop('id', 'read-notification-group-' + container_id);
                    jQuery('#new-archived-group-container .read-all-notifications-button').remove();
                    jQuery('#new-archived-group-container .read-notification-button').remove();
                    jQuery('#new-archived-group-container #read-notification-group-' + container_id + ' .card-body').prepend(notification);
                }
                updateEmptyMessages();

            });

            var last_span = jQuery('#archived-notifications-container-' + container_id).children().find('span').last();

            var text = last_span.text().split(' ');

            var new_text = text[0] + ' ' + text[1] + ' ' + (parseInt(text[2]) + parseInt(result.data)) + ' '+ text[3]+ ' ' + text[4]+ ' ' + text[5];

            last_span.text(new_text);

            module_container.remove();
        },
        error: function (data) {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
        }
    });

}

function updateEmptyMessages() {
    let unread_notifications = jQuery('.unread-notifications-container').find('.card');
    if (unread_notifications.length === 0) {
        jQuery('.unread-notifications-container .empty-notification-text').removeClass('d-none');
    }
    let read_notifications = jQuery('.read-notifications-container').find('.card');

    if (read_notifications.length !== 0) {
        jQuery('.read-notifications-container .empty-notification-text').remove();
    }
}

function dismissNotification(x) {
    x.closest('div').parentElement.remove();
}
