$(document).ready(function () {

    function checkParentContainers(element){
        while(element.closest('.content-container').length>0){
            element = element.closest('.content-container');
            const elementBody = element.children('.card-body');
            const unfinishedBlocks = elementBody.find('.check_button_wrap:not(.ticked_by_me,.ticked_by_other)');
            const unfinishedContainers = elementBody.find('.tickable-title:not(.container_ticked_by_me,.container_ticked_by_other)');
            if(unfinishedBlocks.length + unfinishedContainers.length !== 0){
                break;
            }
            element.children('.content-container-header').find('.tickable-title').addClass('container_ticked_by_me');
            element.children('.content-container-header').siblings('.card-body').addClass('content_ticked_by_me');

            const content_container_id = element.attr('id').split('-')[1];
            const button = $('.toc-container-check[data-contentContainerId="'+content_container_id+'"]');
            button.closest('.container-title-wrap').addClass('finished');

            element=element.closest('.card-body');
        }
    }

    function uncheckParentContainers(element){
        while(element.closest('.content-container').length>0){
            element = element.closest('.content-container');
            const ticked = element.children('.content-container-header').find('.tickable-title.container_ticked_by_me');
            if(ticked.length === 0){
                break;
            }
            ticked.removeClass('container_ticked_by_me');
            ticked.closest('.content-container-header').siblings('.card-body').removeClass('content_ticked_by_me');

            const content_container_id = element.attr('id').split('-')[1];
            const button = $('.toc-container-check[data-contentContainerId="'+content_container_id+'"]');
            button.closest('.container-title-wrap').removeClass('finished');

            element=element.closest('.card-body');
        }
    }

    function updateButtons(button, data){
        const childListItems = button.closest(".list-block").siblings(".list-item-custom:not(.ticked_by_other)");
        const listBlock = button.closest(".list-item-custom").siblings(".list-block");

        if (data.is_checked === 0) {
            button.closest(".row").removeClass('row_ticked_by_me');
            button.closest('.check_button_wrap').removeClass('ticked_by_me');

            childListItems.closest('.can_color').removeClass('row_ticked_by_me');
            childListItems.find('.check_button_wrap').removeClass('ticked_by_me');

            listBlock.removeClass('row_ticked_by_me');
            listBlock.find(".check_button_wrap").removeClass('ticked_by_me');

            uncheckParentContainers(button);
        } else {
            button.closest(".row").addClass('row_ticked_by_me');
            button.closest('.check_button_wrap').addClass('ticked_by_me');

            childListItems.closest('.can_color').addClass('row_ticked_by_me');
            childListItems.find('.check_button_wrap').addClass('ticked_by_me');

            const untickedRows = listBlock.siblings(".list-item-custom:not(.row_ticked_by_me,.row_ticked_by_other)");
            if(untickedRows.length===0) {
                listBlock.addClass('row_ticked_by_me');
                listBlock.find(".check_button_wrap").addClass('ticked_by_me');
            }

            checkParentContainers(button);
        }
    }

    function containerUpdateButtons(button, data){
        if (data.is_checked === 0) {
            button.closest('.check_button_wrap').removeClass('ticked_by_me');

            uncheckParentContainers(button);
        } else {
            button.closest('.check_button_wrap').addClass('ticked_by_me');

            checkParentContainers(button);
        }
    }

    $('.check_button').click(function () {

        let content_block_id = $(this).attr('data-contentBlockId');
        const button = $(this);

        let allChecked = false;
        if($('.tickable-title:not(.container_ticked_by_me,.container_ticked_by_other)').length === 0){
            allChecked = true;
        }

        jQuery.ajax({
            url: `/block_content/check/${content_block_id}`,
            method: 'get',
            success: function (response, status, error) {
                let data = response.data;
                updateButtons(button, data);
                const unchecked = $('.tickable-title:not(.container_ticked_by_me,.container_ticked_by_other)').length;
                if(unchecked === 0 || allChecked === true){
                    location.href = $('.unit-end').first().attr('href') + "/1";
                }
            },
            dataType: 'json',
            error: function (request, status, error) {
                console.log(request.responseText);
            }
        });
    })

    $('.container_check_button').click(function (){
        let content_container_id = $(this).attr('data-contentContainerId');
        const button = $(this);
        const toc_button = $('.toc-container-check[data-contentContainerId="'+content_container_id+'"]');

        let allChecked = false;
        if($('.tickable-title:not(.container_ticked_by_me,.container_ticked_by_other)').length === 0){
            allChecked = true;
        }

        jQuery.ajax({
            url: `/container_content/check/${content_container_id}`,
            method: 'get',
            success: function (response, status, error) {
                let data = response.data;
                console.log(data);

                let action = response.action;

                const card = button.closest('.card');
                if(action === "check") {
                    card.find(".tickable-title:not(.container_ticked_by_other)").addClass('container_ticked_by_me');

                    card.find(".tickable-title:not(.container_ticked_by_other)")
                        .closest('.content-container-header')
                        .siblings('.card-body')
                        .addClass('content_ticked_by_me');

                    card.find(".can_color:not(.row_ticked_by_other)").addClass('row_ticked_by_me');
                    card.find(".check_button_wrap:not(.ticked_by_other)").addClass('ticked_by_me');
                    checkParentContainers(card);

                    toc_button.closest('.container-title-wrap').addClass('finished');
                    toc_button.closest('.container-title-wrap').parent().find('.container-title-wrap:not(.recolor-disabled)').addClass('finished');
                } else {
                    card.find(".tickable-title:not(.check_disabled)").removeClass('container_ticked_by_me');

                    card.find(".tickable-title:not(.check_disabled)")
                        .closest('.content-container-header')
                        .siblings('.card-body')
                        .removeClass('content_ticked_by_me');

                    card.find(".can_color").removeClass('row_ticked_by_me');
                    card.find(".check_button_wrap").removeClass('ticked_by_me');
                    card.parents('.content-container').find('>.content-container-header>.tickable-title:not(.container_ticked_by_other)').removeClass('container_ticked_by_me');

                    toc_button.closest('.container-title-wrap').removeClass('finished');
                    toc_button.closest('.container-title-wrap').parent().find('.container-title-wrap:not(.recolor-disabled)').removeClass('finished');
                    toc_button.parents('.sublist').siblings('.container-title-wrap').removeClass('finished');
                }

                const unchecked = $('.tickable-title:not(.container_ticked_by_me,.container_ticked_by_other)').length;
                if(unchecked === 0 || allChecked === true){
                    location.href = $('.unit-end').first().attr('href') + "/1";
                }
            },
            dataType: 'json',
            error: function (request, status, error) {
                alert(request.responseText);
            }
        });
    });

    $('.container_check_self_button').click(function (){
        let content_container_id = $(this).attr('data-contentContainerId');
        const button = $(this);
        const toc_button = $('.toc-container-check-self[data-contentContainerId="'+content_container_id+'"]');

        let allChecked = false;
        if($('.tickable-title:not(.container_ticked_by_me,.container_ticked_by_other)').length === 0){
            allChecked = true;
        }

        jQuery.ajax({
            url: `/container_content/check_self/${content_container_id}`,
            method: 'get',
            success: function (response, status, error) {
                let data = response.data;
                containerUpdateButtons(button, data);
                const unchecked = $('.tickable-title:not(.container_ticked_by_me,.container_ticked_by_other)').length;
                if(unchecked === 0 || allChecked === true){
                    location.href = $('.unit-end').first().attr('href') + "/1";
                }
            },
            dataType: 'json',
            error: function (request, status, error) {
                alert(request.responseText);
            }
        });
    });

    $('.toc-container-check').click(function () {
        let content_container_id = $(this).attr('data-contentContainerId');
        const button = $('.container_check_button[data-contentContainerId="'+content_container_id+'"]');
        button[0].click();
    });

    $('.toc-container-check-self').click(function () {
        let content_container_id = $(this).attr('data-contentContainerId');
        const button = $('.container_check_self_button[data-contentContainerId="'+content_container_id+'"]');
        button[0].click();
    });
});
