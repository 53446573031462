
let startedChannels = [];
$(document).ready(function () {
    $('.comments-area').hide();


    $('.question-channel').each(function () {

        let question_box_id = $(this).attr('data-questionBoxId');
        if (!startedChannels.includes(question_box_id)) {

            console.log('opening channel:', `question.${question_box_id}`);
            connectToQuestionSocket(question_box_id);

            startedChannels.push(question_box_id);
        }
    })
});

$(document).on('click', '.toggle-comments', function () {
    let question_box_id = $(this).attr('data-questionBoxId');
    $('.comments-area#comments-' + question_box_id).toggle();
    const card = $(this).closest('.card');
    card.children('.card-body').show();

    var mydiv = $(`#response_history_${question_box_id}`);
    mydiv.scrollTop(mydiv.prop("scrollHeight"));
});

$(document).on('click', '.rsp_send_btn', function () {
    let question_box_id = $(this).attr('data-questionBoxId');
    let username = $(this).attr('data-username');

    let module_variant_id = $(this).attr('data-moduleVariantId');
    let unit_id = $(this).attr('data-unitId');
    let customer_id = $(this).attr('data-customerId');
    let type = $(this).attr('data-type');
    let customer_content_id = $(this).attr('data-contentId');
    let question_answers = $(`#question-answers-nr-${question_box_id}`).attr('data-answerNumber');

    // $(`#question-answers-nr-${question_box_id}`).attr('data-answerNumber', parseInt(question_answers) + 1)

    send_message(question_box_id, username, module_variant_id, unit_id, customer_id, type, customer_content_id, question_answers);
    console.log($(this).closest('.replies').siblings());
});

$(document).on('keypress', '.write_rsp', function (event) {
    let keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode === 13) {
        event.preventDefault();

        let respBtn = $(this).next();
        respBtn.trigger('click');
    }
});

function send_message(question_box_id, username, module_variant_id, unit_id, customer_id, type, customer_content_id, question_answers) {

    const initials = username.split(' ')[1].charAt(0) + username.split(' ')[0].charAt(0);

    let text = $(`#response_input_${question_box_id}`).val();

    if (text !== '' && text !== undefined) {

        $(`#response_history_${question_box_id}`).append(`
                        <div class="message outgoing_msg">
                            <div class="message_body">
                                <div class="message_text_wrap">
                                    <div class="message_text">
                                        <p class="user_name"><span class="ml-1">(jetzt)</span> ${username}</p>
                                        <p>${text}</p>
                                    </div>
                                </div>
                                <div class="message_image">
                                    ${initials}
                                </div>
                            </div>
                        </div>
            `);

        //document.getElementById(`question-answers-nr-${question_box_id}`).innerHTML = parseInt(question_answers) + 1 + ' Antworten';
        document.getElementById(
            `response_input_${question_box_id}`).value = '';

        var mydiv = $(`#response_history_${question_box_id}`);
        mydiv.scrollTop(mydiv.prop("scrollHeight"));

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        jQuery.ajax({
            url: '/questions',
            method: 'post',
            data: {
                text: text,
                parent_id: question_box_id,
                is_answered: 0,
                module_variant_id: module_variant_id,
                unit_id: unit_id,
                customer_id: customer_id,
                type: type,
                customer_content_id: customer_content_id
            },
            dataType: 'json',
            error: function (request, status, error) {
                console.log(request.responseText);
            }
        });
    }
}

$(document).on('click', '.question_button.block', function () {
    const customer_content_block_id = $(this).attr('data-customerContentBlockId');
    const button = $(this);

    const module_variant_id = $('#module_variant_id').val();
    const unit_id = $('#unit_id').val();

    const input = $(`#block_question_input_${customer_content_block_id}`);
    let text = input.val();
    input.val('');

    send_ajax_block(text, customer_content_block_id, button, unit_id, module_variant_id);
})

$(document).on('keyup', '.content-block .question_input', function (e) {
    if (e.keyCode == 13) {
        const button = $(this).siblings('.question_button');
        const customer_content_block_id = button.attr('data-customerContentBlockId');
        const module_variant_id = $('#module_variant_id').val();
        const unit_id = $('#unit_id').val();
        let text = $(this).val();
        $(this).val('');

        send_ajax_block(text, customer_content_block_id, button, unit_id, module_variant_id);
    }
});


function send_ajax_block(text, customer_content_block_id, button, unit_id, module_variant_id) {
    if (text !== '' && text !== undefined) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        jQuery.ajax({
            url: '/questions',
            method: 'post',
            data: {
                text: text,
                type: 'block',
                parent_id: null,
                unit_id: unit_id,
                module_variant_id: module_variant_id,
                is_answered: 0,
                customer_content_block_id: customer_content_block_id,
                customer_content_id: customer_content_block_id
            },
            dataType: 'json',
            success: function (response) {
                let question = response.data;

                button.closest('.questions-wrap').parent().find('.toggle-questions').parent().addClass('blue-button');

                const questionIndicators = button.parents('.card-body').siblings('.content-container-header').find('.questions-indicator');
                questionIndicators.addClass('blue-button');

                questionIndicators.each(function () {
                    const container_id = button.attr('data-contentContainerId');
                    $('.table-of-contents').find('.questions-indicator[data-contentContainerId=' + container_id + ']').addClass('blue-button');
                });

                $(`#block_questions_box_${customer_content_block_id}`).append(`
                 <div class="question toggle-replies open_questionbox asked-by-me">
                    <h5>${question.text}</h5>
                    <div class="row"><strong class="ml-2">${response.user_name}</strong><p class="ml-1">jetzt</p></div>
                    <p class="mb-0" id="question-answers-nr-${question.id}" data-questionBoxId="${question.id}" data-answerNumber="0">Antworten hier lesen</p>
                    <div class="replies" style="display: none">
                        <x-question-box :questionBox="${question}"></x-question-box>
                    </div>
                </div>
            `);

            },
            error: function (request, status, error) {
                console.log(request.responseText);
            }
        });
    }
}


$(document).on('click', '.question_button.container', function () {
    const customer_content_container_id = $(this).attr('data-customerContentBlockId');
    const button = $(this);

    const customer_id = $(this).attr('data-customerId');
    const module_variant_id = $('#module_variant_id').val();
    const unit_id = $('#unit_id').val();


    const input = $(`#container_question_input_${customer_content_container_id}`);
    let text = input.val();
    input.val('');


    send_ajax_container(text, customer_content_container_id, button, customer_id, module_variant_id, unit_id);
});

$(document).on('keyup', '.content-container .question_input', function (e) {
    if (e.keyCode === 13) {
        const button = $(this).siblings('.question_button');
        const customer_content_container_id = button.attr('data-customerContentBlockId');
        const customer_id = button.attr('data-customerId');
        const module_variant_id = $('#module_variant_id').val();
        const unit_id = $('#unit_id').val();
        let text = $(this).val();
        $(this).val('');

        send_ajax_container(text, customer_content_container_id, button, customer_id, module_variant_id, unit_id);
    }
});

function connectToQuestionSocket(question) {
    Echo.channel(`question.${question}`)
        .listen('QuestionSentEvent', e => {
            console.log("Received Question Event: ");
            console.log(e);

            const initials = e.user.surname.charAt(0) + e.user.name.charAt(0);
            $(`#response_history_${question}`).append(`
                        <div class="message incoming_msg">
                            <div class="message_body">
                                <div class="message_image">
                                    ${initials}
                                </div>
                                <div class="message_text_wrap">
                                    <div class="message_text">
                                        <p class="user_name">${e.user.name} ${e.user.surname} <span class="ml-1">(jetzt)</span></p>
                                        <p>${e.question.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                `)
            let question_answers = $(`#question-answers-nr-${question}`).attr('data-answerNumber');

            // $(`#question-answers-nr-${question}`).attr('data-answerNumber', parseInt(question_answers) + 1);
            // document.getElementById(`question-answers-nr-${question}`).innerHTML = parseInt(question_answers) + 1 + ' Antworten';

            var mydiv = $(`#response_history_${question}`);
            mydiv.scrollTop(mydiv.prop("scrollHeight"));
        });
}

function send_ajax_container(text, customer_content_container_id, button, customer_id, module_variant_id, unit_id) {
    if (text !== '' && text !== undefined) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        jQuery.ajax({
            url: '/questions',
            method: 'post',
            data: {
                text: text,
                parent_id: null,
                type: 'container',
                unit_id: unit_id,
                module_variant_id: module_variant_id,
                is_answered: 0,
                customer_content_container_id: customer_content_container_id,
                customer_content_id: customer_content_container_id
            },
            dataType: 'json',
            success: function (response) {
                let question = response.data;

                const questionIndicators = button.parents('.card-body').siblings('.content-container-header').find('.questions-indicator');
                questionIndicators.addClass('blue-button');

                questionIndicators.each(function () {
                    const container_id = button.attr('data-contentContainerId');
                    $('.table-of-contents').find('.questions-indicator[data-contentContainerId=' + container_id + ']').addClass('blue-button');
                });

                if(response.logged_user.roles[0].name === 'User'){
                    var placeholder = "Bitte schreiben Sie hier Ihre Nachricht";
                }else{
                    var placeholder = "Bitte schreiben Sie hier Ihre Antwort";
                }

                $(`#container_questions_box_${customer_content_container_id}`).append(`
                 <div class="question toggle-replies open_questionbox asked-by-me">
                    <h5>${question.text}</h5>
                    <div class="row"><strong class="ml-2">${response.user_name}</strong><p class="ml-1">jetzt</p></div>
                    <p class="mb-0" data-questionBoxId="${question.id}" id="question-answers-nr-${question.id}"  data-answerNumber="0">Antworten hier lesen</p>
                    <div class="replies" style="display: none">
                        <div class="messaging">
                            <div class="inbox_msg">
                                <h3 class="pl-4 pt-3">Antworten</h3>
                                    <div class="mesgs">
                                    <div class="rsp_history question-channel" id="response_history_${question.id}" data-questionBoxId="${question.id}"></div>
                                    <div class="type_msg">
                                    <div class="input_msg_write p-2">
                                        <textarea class="write_rsp ml-2 p-2" style="border: none;width: 85%;" id="response_input_${question.id}"
                                        data-questionBoxId="${question.id}" data-username="${response.logged_user.name} ${response.logged_user.surname}"
                                        placeholder="${placeholder}"></textarea>
                                        <button type="button" class="rsp_send_btn epd_primary mr-2 p-1" data-questionBoxId="${question.id}"
                                        data-username="${response.logged_user.name} ${response.logged_user.surname}" data-moduleVariantId="${question.module_variant_id}"
                                        data-unitId="${question.unit_id}" data-customerId="${question.customer_id}" data-contentId="${question.customer_content_id}"
                                        data-type="${question.type}">
                                        <i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                            </div>
                    </div>
                </div>
            `);

                connectToQuestionSocket(question.id);

                startedChannels.push(question.id);
            },
            error: function (request, status, error) {
                console.log(request.responseText);
            }
        });
    }
}

