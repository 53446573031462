$(document).ready(function () {
    $('#module-variants-table').DataTable({
        dom: 'fBlrtip',
        language: {
            url: '/data_tables_translation.json'
        }
    });

    let $token = $('#token').val();

    $('#add-element').click(function(){

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $token
            }
        });
        $.ajax({
            url: location.protocol + '//' + location.host + '/add-elem',
            method: 'post',
            data: {
                id: $('#elem-select').val(),
            },
            success: function(result){

                var html = "<tr id ='row-"+ result.id +"'> " +
                    "<td><input type='text' class='form-control order-input' name='order-"+ result.id +"' readonly></td> " +
                    "<td>"+ result.id +"</td> " +
                    "<td>"+ result.intern_name +"</td> " +
                    "<td><p class='btn btn-up'><i class='fa fa-chevron-up' aria-hidden='true'></i></p> " +
                    "<p class='btn btn-down'><i class='fa fa-chevron-down' aria-hidden='true'></i></p> " +
                    "<p class='btn remove-row' ><i class='fa fa-trash-alt' aria-hidden='true'></i></p> " +
                    "</td> " +
                    "</tr>";

                $('#unit-order tbody').append(html);
                change_order();
                $("#elem-select option:selected").remove();
            }});
    });

    $(document).on('click', '.remove-row', function(){
        var row = $(this).closest('tr');
        var id = $(this).closest('tr').attr('id').split('-')[1];

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $token
            }
        });
        $.ajax({
            url: location.protocol + '//' + location.host + '/remove-elem',
            method: 'post',
            data: {
                id: id,
            },
            success: function(result){
                var html = "<option value='"+ result.id +"'>"+ result.title +"</option>";
                row.remove();
                $('#elem-select').append(html);
                change_order();
            }});
    });

    $(document).on('click','.btn-up',function () {
        var current_index = $(this).closest("tr").index();
        if(current_index !== 0){
            var new_index = current_index+1;
            const row1 = $(".module_var_table tbody tr:nth-child("+ current_index +")");
            const row2 =$(".module_var_table tbody tr:nth-child("+ new_index +")");
            row1.insertAfter(row2);
        }
        change_order();
    });

    $(document).on('click','.btn-down', function () {
        var current_index = $(this).closest("tr").index()+1;
        var rowCount = $('#unit-order tr').length -1;

        if(current_index !== rowCount){
            var new_index = current_index+1;

            $(".module_var_table tbody tr:nth-child("+ current_index +")").insertAfter($(".module_var_table tbody tr:nth-child("+ new_index +")"));
        }
        change_order();
    });

    function change_order(){
        $('.order-input').each( function (index){
                $(this).val(index+1);
            }
        );
    }
});


