import {change_order,update_form} from "./content-builder";

function liveSaveContainer(form, button){
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    const prompt = button.text();
    const data = form.serialize();

    jQuery.ajax({
        url: form.attr("action"),
        type: "post",
        data: data,
        beforeSend: function () {
            button.html('<i class="fas fa-spinner fa-spin"></i>')
            button.prop('disabled', true);
        },
        success: function (data) {
            if(data['action'] === 'store'){
                const containerOrder = form.find('input[name=order]');
                containerOrder.val(parseInt(containerOrder.val())+1);

                const header = button.closest('.card-header');
                if(header.hasClass('main-unit-header')){
                    const containers = header.siblings('.card-body').children('.content-accordion');
                    containers.append(data['html']);
                }else{
                    const containers = header.siblings('.card-body');
                    containers.append(data['html']);
                    containers.children('.block-forms-container').html(data['forms']);
                }
                resetEditors();

                emptyFormInputs(form);
                form.closest('.modal').modal('hide');
                toastr.success('Gespeichert');
            }
            else if( data['action'] === 'update' ){
                form.closest('.modal').modal('hide');

                const card = button.closest('.card');
                const parent = card.parent();
                card.hide();
                parent.append(data['html']);
                resetEditors();

                toastr.success('Aktualisiert');

                setTimeout(function removeOldCard() {
                    card.remove();
                }, 1000)
            }
            button.html(prompt);
            button.prop('disabled', false);
        },
        error: function (data) {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
            button.html(prompt);
            button.prop('disabled', false);
        }
    });
}

function liveDeleteContainer(form, button){
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    const data = form.serialize();

    jQuery.ajax({
        url: form.attr("action"),
        type: "post",
        data: data,
        beforeSend: function () {
            button.html('<i class="fas fa-spinner fa-spin"></i>')
            button.prop('disabled', true);
        },
        success: function (data) {
            const orderedCard = button.closest('.card');
            deleteAndReorder(orderedCard, data);
        },
        error: function (data) {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
        }
    });
}

function CleanPastedHTML(input) {
    let i;

    // 1. remove line breaks / Mso classes
    const stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
    let output = input.replace(stringStripper, ' ');

    // 2. strip Word generated HTML comments
    const commentSripper = new RegExp('<!--(.*?)-->', 'g');
    output = output.replace(commentSripper, '');

    // 3. remove tags leave content if any
    let tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi');
    output = output.replace(tagStripper, '');

    // 4. Remove everything in between and including tags '<style(.)style(.)>'
    const badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript'];
    for (i = 0; i< badTags.length; i++) {
        tagStripper = new RegExp('<'+badTags[i]+'.*?'+badTags[i]+'(.*?)>', 'gi');
        output = output.replace(tagStripper, '');
    }

    // 5. remove attributes ' style="..."'
    const badAttributes = ['style', 'start'];
    for (i = 0; i< badAttributes.length; i++) {
        var attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"','gi');
        output = output.replace(attributeStripper, '');
    }

    return output;
}

function resetEditors() {
    $('.quill-editor').summernote({
        disableDragAndDrop: true,
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'italic', 'clear']],
            ['fontname', ['fontname']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link']],
            ['view', ['fullscreen', 'codeview']],
        ],
        callbacks: {
            onPaste: function (e) {
                e.preventDefault();

                var original = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html');
                var cleaned = CleanPastedHTML(original);

                document.execCommand('insertHtml', false, cleaned);
            }
        }
    });
}

function liveSaveBlock(blockRoute, clickedBtn) {
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    const prompt = clickedBtn.text();
    let content_block_id = clickedBtn.attr('data-blockableId');
    let context = clickedBtn;

    let dataToSend = {};
    let currentForm = context.closest('form');
    let currentFormData = currentForm.serializeArray();

    if (blockRoute === '/list_block/') {
        dataToSend['list_item_de'] = [];
        dataToSend['list_item_fr'] = [];

        currentFormData.forEach(element => {
            if(element.value !== '') {
                switch (element.name) {
                    case 'list_item_de[]':
                        dataToSend['list_item_de'].push(element.value);
                        break;
                    case 'list_item_fr[]':
                        dataToSend['list_item_fr'].push(element.value);
                        break;
                    default:
                        if (element.value === "")
                            dataToSend[element.name] = null;
                        else
                            dataToSend[element.name] = element.value;
                        break;
                }
            }
        });
    } else {
        currentFormData.forEach(element => {
            if (element.value === "")
                dataToSend[element.name] = null;
            else
                dataToSend[element.name] = element.value;
        });
    }

    if (content_block_id === undefined) {
        content_block_id = '';
    }

    jQuery.ajax({
        url: blockRoute + content_block_id,
        type: "post",
        data: dataToSend,
        beforeSend: function () {
            context.html('<i class="fas fa-spinner fa-spin"></i>');
            context.prop('disabled', true);
        },
        success: function (data) {
            context.html(prompt);
            context.prop('disabled', false);
            toastr.success('Gespeichert');
            const block_id = data['data']['id'];
            const generic_block_id = data['block_id'];
            const block_title = dataToSend['content_title_de'];
            if (content_block_id === '') {
                appendNewBlock(block_title, clickedBtn, block_id, generic_block_id);
            }
        },
        error: function () {
            context.html('Speichern');
            context.prop('disabled', false);
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
        }
    });
}


function liveSaveImage(clickedBtn) {
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    const prompt = clickedBtn.text();
    let content_block_id = clickedBtn.attr('data-blockableId');

    let form = clickedBtn.closest('form');
    let formData = new FormData(form[0]);

    jQuery.ajax({
        url: '/image_block/' + content_block_id,
        type: "post",
        data: formData,
        contentType: false,
        processData: false,
        beforeSend: function () {
            clickedBtn.html('<i class="fas fa-spinner fa-spin"></i>');
            clickedBtn.prop('disabled', true);
        },
        success: function (data) {
            clickedBtn.html(prompt);
            clickedBtn.prop('disabled', false);
            toastr.success('Gespeichert');

            const block_id = data['data']['id'];
            const generic_block_id = data['block_id'];
            const block_title = formData.get('content_title_de');
            if (content_block_id === '') {
                appendNewBlock(block_title, clickedBtn, block_id, generic_block_id);
            }
        },
        error: function () {
            clickedBtn.html(prompt);
            clickedBtn.prop('disabled', false);
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
        }
    });
}

function appendNewBlock(title, clickedBtn, block_id, generic_block_id) {
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    jQuery.ajax({
        url: '/render_block/'+generic_block_id,
        type: "post",
        data: {},
        success: function (data) {
            clickedBtn.attr('data-blockableId', block_id);
            const new_block_body = jQuery(clickedBtn).closest('.new-block-form');
            const container_body = new_block_body.closest('.card-body');

            var order = container_body.children().not('.block-forms-container').length;
            var containerOrder = container_body.closest('.card').children('.card-header').find('input[name=order]');
            containerOrder.val(order+1);

            container_body.children('.block-forms-container').html(data['forms']);

            container_body.append(data['html']);

            resetEditors();
        }
    });
}

function emptyFormInputs(form) {
    form.find('input')
        .not(':button, :submit, :reset, :hidden')
        .val('')
        .prop('checked', false)
        .prop('selected', false);
}

function deleteContentBlock(form, context) {
    jQuery.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content'),
            "Accept": "application/json"
        }
    });

    let url = form.attr('action');
    let method = 'delete';

    jQuery.ajax({
        url: url,
        type: method,
        beforeSend: function () {
            context.html('<i class="fas fa-spinner fa-spin"></i>');
            context.prop('disabled', true);
        },
        success: function (data) {
            toastr.success('Inhalt entfernt');

            let orderedCard = form.closest('.ordered-card');
            deleteAndReorder(orderedCard, data);
        },
        error: function (data) {
            toastr.error('Wir hatten einen Problem, bitte versuchen sie später');
        }
    });
}

function deleteAndReorder(orderedCard, data){
    let removedPosition = orderedCard.css('order');
    for(const sibling of orderedCard.siblings('.ordered-card')){
        let siblingTransformed = $(sibling);
        if(siblingTransformed.css('order')>removedPosition){
            let newPosition = parseInt(siblingTransformed.css('order'))-1;
            siblingTransformed.css('order',newPosition);

            let csrf = jQuery('meta[name="csrf-token"]').attr('content');
            let routeCurrent = siblingTransformed.find('.move-up').attr('route');
            change_order(newPosition,routeCurrent,csrf);
            update_form(siblingTransformed,newPosition);
        }
    }
    const container_body = orderedCard.closest('.card-body');
    const containerOrder = container_body.closest('.card').children('.card-header').find('input[name=order]');
    containerOrder.val(parseInt(containerOrder.val())-1);

    if(data['forms'] != null){
        container_body.children('.block-forms-container').html(data['forms']);
        resetEditors();
    }

    orderedCard.remove();
}

$(document).ready(function () {
    var unitsList = jQuery('#units-list');

    unitsList.on('click', '.save-title-block-button', function (e) {
        liveSaveBlock('/title_block/', $(this));
    })

    unitsList.on('click', '.save-paragraph-block-button', function (e) {
        e.preventDefault();
        liveSaveBlock('/paragraph_block/', $(this));
    })

    unitsList.on('click', '.save-image-block-button', function (e) {
        e.preventDefault();
        liveSaveImage($(this));
    })

    unitsList.on('click', '.save-video-block-button', function (e) {
        e.preventDefault();
        liveSaveBlock('/video_block/', $(this));
    })

    unitsList.on('click', '.save-list-block-button', function (e) {
        liveSaveBlock('/list_block/', $(this));
    })

    unitsList.on('click', '.save-table-block-button', function (e) {
        liveSaveBlock('/table_block/', $(this));
    })

    $(document).on('click', '.delete-reoreder', function (e) {
        e.preventDefault();
        if(confirm('Sind sie Sicher?')){
            let deleteBtn = $(this);
            let form = $(this).closest('form');
            deleteContentBlock(form, deleteBtn);
        }
    });

    $(document).on('click', '.add-or-update-content-container', function (e) {
        const form = $(this).closest('form');
        liveSaveContainer(form, $(this));
    })

    $(document).on('click', '.delete-content-container', function (e) {
        if(!confirm('Sind sie Sicher?')){
            return;
        }
        const form = $(this).closest('form');
        liveDeleteContainer(form, $(this));
    })
});
