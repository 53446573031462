
$('#questions_unread').on('click',function (){
   $('#unread_question_list').show();
   $('#read_question_list').hide();
    $('#questions_read').removeClass('btn-primary');
    $('#questions_read').addClass('btn-outline-primary');
    $(this).addClass('btn-primary');
    $(this).removeClass('btn-outline-primary');
});


$('#questions_read').on('click',function (){
    $('#unread_question_list').hide();
    $('#read_question_list').show();
    $('#questions_unread').removeClass('btn-primary');
    $('#questions_unread').addClass('btn-outline-primary');
    $(this).addClass('btn-primary');
    $(this).removeClass('btn-outline-primary');
});
