$(document).on('change', '#activation', function () {
    const text = this.options[this.selectedIndex].text;
    const regex = /automatisch bei abschluss/gi;

    if (text.match(regex)) {
        $('#unlocked_by')
            .prop('disabled', false)
            .prop('required', true);
    } else {
        $('#unlocked_by')
            .prop('disabled', true)
            .prop('required', false)
            .prop('selectedIndex', 0);
    }
});

$(document).ready(function () {
    const text = $('#activation').find(":selected").text();
    const regex = /automatisch bei abschluss/gi;

    if (text.match(regex)) {
        $('#unlocked_by')
            .prop('disabled', false)
            .prop('required', true);
    }

    var container_id = jQuery('#container_id').val();
    jQuery('#'+container_id).trigger("click");

});

$(document).ready(function () {
    $('#unit_data_table').DataTable({
        "order": [[ 1, "asc" ]],
        dom: 'fBlrtip',
        language: {
            url: '/data_tables_translation.json'
        }
    });
});

$(document).on('click', '.toggle-visibility-all', function () {
    const id = $(this).attr('id');

    $(this).hide();
    if (id === 'show-all') {
        $('.content-accordion .card-body:not(.orphaned)').show();
        $('.toggle-visibility-all#hide-all').show();

    } else if (id === 'hide-all') {
        $('.content-accordion .card-body:not(.orphaned)').hide();
        $('.toggle-visibility-all#show-all').show();
    }
});

function setupStickyHeader() {
    var stickyHeader = $("#stickyheader");
    let fixedHeader = $('#fixedheader');

    var scrollablePart = document.body.offsetHeight - window.innerHeight;
    let sticky_height = parseInt(localStorage.getItem('stickyInhaltHeight'));
    if (scrollablePart > 700) {
        if ($(window).scrollTop() >= sticky_height) {
            fixedHeader.css('visibility', 'hidden');
            stickyHeader.show();
            if (!stickyHeader.hasClass("sticky")) {

                //stickyheader.parent().css('padding-top', stickyheader.height())
                stickyHeader.addClass("sticky");
                stickyHeader.find('.card-body').hide();
            }
        } else {
            if (stickyHeader.hasClass("sticky")) {
                fixedHeader.css('visibility', 'visible');
                stickyHeader.hide();
                //stickyheader.parent().css('padding-top', 0)
                stickyHeader.removeClass("sticky");
                stickyHeader.find('.card-body').show();
            }
        }
    }
}

$(document).ready(function () {

    var header1 = $("#fixedheader");
    if (header1.offset()) {
        localStorage.setItem('stickyInhaltHeight', header1.height() + header1.offset().top);
    }
    window.onscroll = function () {
        setupStickyHeader()
    };

    const isBasic = $('#unit_is_basic');
    console.log(isBasic.val());
    if(isBasic.val()!==0) {
        if (isBasic.length !== 0) {
            $('.toggle-visibility-all#show-all').hide();
            $('.content-accordion .card-body:not(.orphaned)').show();
            $('.toggle-visibility-all#hide-all').show();
        }
    }
})

function disable_checkbox(select) {
    const value = select.options[select.selectedIndex].value;
    const checkbox = $(select).closest('tr').find('.activated');

    if (parseInt(value) === 1) {
        checkbox
            .prop('disabled', true)
            .prop('checked', true);
    } else {
        checkbox
            .prop('disabled', false);
    }

    if (parseInt(value) === 3) {
        checkbox
            .prop('disabled', true)
            .prop('checked', false);
    }
}

$(document).on('change', '.activation_type_id', function () {
    disable_checkbox(this);
});

$(document).on('change', '.activation_type_id, .activated, .unlocked_by_dropdown', function () {
    const button = $(this).closest('tr').find('.update-activation');
    button.prop('disabled', false);
});

$(document).ready(function () {
    $('.activation_type_id').each(function () {
        disable_checkbox(this);
    });

    $(".activation_type_id").on('change', function () {
        let selected = $(this).val();
        let toShow = $(this).next();
        if (parseInt(selected) === 3) {
            toShow.removeAttr('hidden');
        } else {
            toShow.attr('hidden', true);
        }
    })
});
