$(document).ready( function () {
    $('#users_table').DataTable({
        dom: 'fBlrtip',
        language: {
            url: '/data_tables_translation.json'
        }
    });
} );

document.addEventListener("DOMContentLoaded", function() {
    var elements = document.getElementsByTagName("INPUT");
    for (var i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function(e) {
            e.target.setCustomValidity("");
            if (!e.target.validity.valid) {
                e.target.setCustomValidity("Bitte füllen Sie dieses Feld aus.");
            }
        };
        elements[i].oninput = function(e) {
            e.target.setCustomValidity("");
        };
    }

    var dropdowns = document.getElementsByTagName("SELECT");
    for (var j = 0; j < dropdowns.length; j++) {
        dropdowns[j].oninvalid = function(e) {
            e.target.setCustomValidity("");
            if (!e.target.validity.valid) {
                e.target.setCustomValidity("Bitte wählen Sie eine Wahl.");
            }
        };
        dropdowns[j].oninput = function(e) {
            e.target.setCustomValidity("");
        };
    }
})
