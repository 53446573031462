$(document).ready(function () {
    $('.comments-area').hide();
});

function to_card(card){
    card.find('.card-body').show();
    card.find('.card-header').find('.fa-chevron-down').addClass('open');

    card.parents('.card').children('.card-body').show();
    card.parents('.card').children('.card-header').find('.fa-chevron-down').addClass('open');

    $('html, body').animate({
        scrollTop: card.offset().top - 80
    }, 1000);
}

$(document).on('click', '.table-of-contents .container-title', function () {
    let card = $('#' + $(this).attr('go-to'));
    to_card(card);
    if ($(this).closest('.ordered-card').hasClass('sticky')) {
        $(this).closest('.card-body').hide();
    }
});

$(document).on('click', '.toggle-container-questions', function () {
    const cardBody = $(this).closest('.content-container-header').siblings('.card-body');
    cardBody.show();
    const questions = cardBody.find('.container-questions').parent();
    questions.toggle();
    var mydiv = questions.find(`.rsp_history`);
    mydiv.scrollTop(mydiv.prop("scrollHeight"));
});

$(document).on('click', '.go-to-questions', function () {
    let container_id = $(this).attr('data-contentContainerId');
    const card = $('#container-' + container_id);

    to_card(card);

    card.children('.card-body').find('.container-questions').parent().show();
});

$(document).on('click', '.go-to-reminders', function () {
    let container_id = $(this).attr('data-contentContainerId');
    const card = $('#container-' + container_id);

    to_card(card);

    setTimeout(
        function()
        {
            card.find('.open-modal').first().click();
        }, 1000
    );
});

$(document).on('click', '.toggle-comments', function () {
    let comment_box_id = $(this).attr('data-commentBoxId');
    $('.comments-area#comments-' + comment_box_id).toggle();
    const card = $(this).closest('.card');
    card.children('.card-body').show();
    card.children('.card-header').find('.fa-chevron-down').addClass('open');

    var mydiv = $(`#message_history_${comment_box_id}`);
    mydiv.scrollTop(mydiv.prop("scrollHeight"));
});

$(document).on('click', '.go-to-comments', function () {
    let comment_box_id = $(this).attr('data-commentBoxId');
    const comments = $('.comments-area#comments-' + comment_box_id);
    const card = comments.closest('.card');

    to_card(card);

    comments.show();
});

$(document).ready(() => {

    $('.comment-channel').each(function () {
        let comment_box_id = $(this).attr('data-commentBoxId');

        $('.chatbox-' + comment_box_id).parent().removeClass('comment-new-messages');

        Echo.channel(`comment_box.${comment_box_id}`)
            .listen('MessageSentEvent', e => {
                console.log("Received Message Event",e);
                const initials = e.user.surname.charAt(0) + e.user.name.charAt(0);
                $(`#message_history_${comment_box_id}`).append(`
                        <div class="message incoming_msg">
                            <div class="message_body">
                                <div class="message_image">
                                    ${initials}
                                </div>
                                <div class="message_text_wrap">
                                    <div class="message_text">
                                        <p class="user_name">${e.user.name} ${e.user.surname} <span style="font-weight: normal">(jetzt)</span></p>
                                        <p>${e.comment.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                `)
                $(`#notice_${comment_box_id}`).css('display', 'none');

                // var mydiv = $(".msg_history");
                // mydiv.scrollTop(mydiv.prop("scrollHeight"));
                var mydiv = $(`#message_history_${comment_box_id}`);
                mydiv.scrollTop(mydiv.prop("scrollHeight"));
            })
    })

    $('.open_chatbox').click(function () {

        // let comment_box_id = $(this).attr('data-commentBoxId');
        // console.log("removing class from "+'chatbox-'+comment_box_id);
        // $('.chatbox-'+comment_box_id).parent().removeClass('comment-new-messages');
        //
        // Echo.channel(`comment_box.${comment_box_id}`)
        //     .listen('MessageSentEvent', e => {
        //         console.log(e);
        //         const initials = e.user.surname.charAt(0) + e.user.name.charAt(0);
        //         $(`#message_history_${comment_box_id}`).append(`
        //                 <div class="message incoming_msg">
        //                     <div class="message_body">
        //                         <div class="message_image">
        //                             ${initials}
        //                         </div>
        //                         <div class="message_text_wrap">
        //                             <div class="message_text">
        //                                 <p class="user_name">${e.user.name} ${e.user.surname}</p>
        //                                 <p>${e.comment.text}</p>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //         `)
        //         $(`#notice_${comment_box_id}`).css('display', 'none');
        //
        //         // var mydiv = $(".msg_history");
        //         // mydiv.scrollTop(mydiv.prop("scrollHeight"));
        //         var mydiv = $(`#message_history_${comment_box_id}`);
        //         mydiv.scrollTop(mydiv.prop("scrollHeight"));
        //     })
    })


    $(document).on('click','.msg_send_btn', function () {
        let comment_box_id = $(this).attr('data-commentBoxId');
        let username = $(this).attr('data-username');
        let container = $(this).attr('data-containerId');
        console.log('test');
        console.log('container', container);

        send_message(comment_box_id, username, container);
    });

    $('.write_msg').on('focusin,click', function (e) {
        let comment_box_id = $(this).attr('data-commentBoxId');
        console.log("removing class " + comment_box_id);
        $('chatbox-' + comment_box_id).parent().removeClass('comment-new-messages');
    });

    $(document).on('keypress','.write_msg', function (event) {
        let keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode === 13) {
            event.preventDefault();

            let comment_box_id = $(this).attr('data-commentBoxId');
            let username = $(this).attr('data-username');


            console.log("removing class from " + 'chatbox-' + comment_box_id);
            $('.chatbox-' + comment_box_id).parent().removeClass('comment-new-messages');

            let container = $(this).attr('data-containerId');
            console.log('container',container);
            send_message(comment_box_id, username, container);
        }
    });

    function send_message(comment_box_id, username, container_id) {

        const initials = username.split(' ')[1].charAt(0) + username.split(' ')[0].charAt(0);

        let text = $(`#message_input_${comment_box_id}`).val();
        let date = $(`#message_input_${comment_box_id}`).attr('data-dateTime');

        const unit_id = $('#unit_id').val();
        console.log(unit_id);
        console.log('container_id', container_id);
        console.log('text', text);

        if (text !== '' && text !== undefined) {
            //console.log('text defined', text);

            $(`#message_history_${comment_box_id}`).append(`
                        <div class="message outgoing_msg">
                            <div class="message_body">
                                <div class="message_text_wrap">
                                    <div class="message_text">
                                        <p class="user_name"><span style="font-weight: normal">(jetzt)</span> ${username}</p>
                                        <p>${text}</p>
                                    </div>
                                </div>
                                <div class="message_image">
                                    ${initials}
                                </div>
                            </div>
                        </div>
            `);
            $(`#notice_${comment_box_id}`).css('display', 'none');

            document.getElementById(
                `message_input_${comment_box_id}`).value = '';

            // var mydiv = $(".msg_history");
            // mydiv.scrollTop(mydiv.prop("scrollHeight"));
            var mydiv =  document.getElementById(`message_history_${comment_box_id}`);
            mydiv.scrollTop = mydiv.scrollHeight;


            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            jQuery.ajax({
                url: '/comments',
                method: 'post',
                data: {
                    text: text,
                    parent_id: null,
                    comment_box_id: comment_box_id,
                    unit_id: unit_id,
                    container_id: container_id
                },
                dataType: 'json',
                error: function (request, status, error) {
                    console.log(request.responseText);
                }
            });
        }
    }

})
